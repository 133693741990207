import { setTranslations } from "polotno/config";
setTranslations({

  sidePanel: {
    text: "النصوص",
    myFonts: "خطوطي",
    googleFonts: "خطوط جوجل",
    elements: "الأشكال",
    background: "الخلفية",
    templates: "القوالب",
    photos: "الصور",
    upload: "رفع",
    layers: "الطبقات",
    resize: "مقاس",
    bodyText: "نص",
    createBody: "نص",
    createHeader: "عنوان",
    createSubHeader: "عنوان فرعي",
    error: "خطأ",
    headerText: "عنوان",
    height: "ارتفاع",
    layersTip: "عناصر التصميم",
    magicResizeDescription: "تغيير المقاس وترتيب العناصر بشكل آلي",
    namePlaceholder: "أسم العنصر",
    noLayers: "لا يوجد عناصر",
    noResults: "لا يوجد نتائج",
    otherFormats: "صيغ أخرى",
    searchPlaceholder: "بحث",
    searchTemplatesWithSameSize: "عرض عن قوالب بنفس المقاس",
    subHeaderText: "عنوان فرعي",
    uploadFont: "رفع خط",
    uploadImage: "رفع صورة",
    uploadTip: "هل تريد رفع صورك الخاصة؟",
    useMagicResize: "تغيير المقاس",
    width: "عرض",
  },
  toolbar: {
    alignBottom: "محاذاة للأسفل",
    alignCenter: "محاذاة للوسط",
    alignLeft: "محاذاة لليسار",
    alignMiddle: "محاذاة للوسط",
    alignRight: "محاذاة لليمين",
    alignTop: "محاذاة للأعلى",
    alwaysOnTop: "دائما في الأعلى",
    blur: "تمويه",
    border: "الحدود",
    brightness: "السطوع",
    cancelRemoveBackground: "إلغاء إزالة الخلفية",
    color: "اللون",
    confirmRemoveBackground: "تأكيد إزالة الخلفية",
    contentEditable: "قابل للتعديل",
    cornerRadius: "نعومة الزاوية",
    crop: "قص",
    cropCancel: "إلغاء",
    cropDone: "تم",
    down: "أسفل",
    download: "تحميل",
    draggable: "قابل للسحب",
    duplicateElements: "تكرار العناصر",
    effects: "التأثيرات",
    fitToBackground: "ملائمة للخلفية",
    flip: "قلب",
    flipHorizontally: "قلب افقي",
    flipVertically: "قلب عمودي",
    grayscale: "درجات الرمادي",
    layering: "الطبقات",
    letterSpacing: "المسافة بين الحروف",
    lineHeight: "ارتفاع السطر",
    lockedDescription:
      "تم تأمين العنصر لمنع التعديلات عليه. إذا كنت تريد تغيير العنصر، قم بإزالة القفل أولاً",
    offsetX: "الإزاحة الأفقية",
    offsetY: "الإزاحة العمودية",
    opacity: "الشفافية",
    position: "الترتيب",
    removeBackground: "إزالة الخلفية",
    removeClip: "إزالة القص",
    removeElements: "إزالة العناصر",
    removeMask: "إزالة القناع",
    saveAsImage: "حفظ كصورة",
    saveAsPDF: "حفظ كـ PDF",
    selectable: "قابل للتحديد",
    sepia: "Sepia",
    shadow: "الظل",
    showInExport: "عرض في التصدير",
    styleEditable: "قابل للتعديل",
    textStroke: "حدود النص",
    toBottom: "إلى الأسفل",
    toForward: "إلى الاعلى",
    transparency: "الشفافية",
    unlockedDescription: "العنصر مفتوح للتعديل. قم بإغلاقه لتجنب التعديلات  ",
    up: "أعلى",
  },
  workspace: {
    addPage: "إضافة صفحة",
    duplicatePage: "نسخ الصفحة",
    moveDown: "تحريك لأسفل",
    moveUp: "تحريك لأعلى",
    noPages: "لا يوجد صفحات",
    removePage: "حذف الصفحة",
  },
});
