import React from 'react';
import ReactDOM from 'react-dom/client';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { SidePanel } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
import localforage from 'localforage';
import { setGoogleFonts } from 'polotno/config';
// custom topbar
import Topbar from './custom/topbar';

import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';

// translation
// eslint-disable-next-line
import translation from './custom/translation.js';

// styles

import { createStore } from 'polotno/model/store';



import {
  TextSection,
  PhotosSection,
  ElementsSection,
  UploadSection,
  BackgroundSection,
  SizeSection,
  LayersSection,
} from 'polotno/side-panel';
import { TemplatesSection }  from './custom/Templates-section.js';
import { PrivateTemplates } from './custom/PrivateTemplates.js';
import { IconsSection } from './custom/icons-section.js';

import './index.css';

// Private Sections
import { YaneaSection } from './custom/yanea-section';
import { Take1Section } from './custom/Take1-section.js';

// end imports 


const store = createStore({
  key: 'RPIPC9UCFHeFQomXFAcb',
});
// eslint-disable-next-line

// active sections
const sections = [ TemplatesSection ,TextSection, PhotosSection, BackgroundSection, LayersSection, IconsSection,  SizeSection, ElementsSection, UploadSection, PrivateTemplates, YaneaSection, Take1Section];

// fonts 
setGoogleFonts(["Alexandria","Alkalami","Almarai","Amiri", "Aref Ruqaa", "Baloo Bhaijaan 2", "Blaka", "Blaka Hollow", "Cairo", "Changa", "El Messiri", "Gulzar", "Harmattan", "IBM Plex Sans Arabic", "Jomhuria", "Katibeh", "Kufam", "Lalezar", "Lateef", "Lemonada", "Mada", "Marhey", "Markazi Text", "Mirza", "Noto Kufi Arabic", "Noto Naskh Arabic",  "Noto Nastaliq Urdu", "Noto Sans Arabic", "Qahiri", "Rakkas", "Readex Pro", "Reem Kufi", "Tajawal", "Vazirmatn", "Vibes"]);

const useHeight = () => {
  const [height, setHeight] = React.useState(window.innerHeight);
  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight);
    });
  }, []);
  return height;
};

export const App = ({ store }) => {
  const height = useHeight();

  return (
    <div
    style={{
      width: '100vw',
      height: height + 'px',
      display: 'flex',
      flexDirection: 'column',
    }}  >
                <Topbar store={store}  />

          <div style={{ height: 'calc(100% - 50px)' }}>
    <PolotnoContainer >
      <SidePanelWrap>
        <SidePanel store={store} sections={sections} defaultSection='custom-templates' />
      </SidePanelWrap>
      <WorkspaceWrap>
        <Toolbar store={store}  />
        {/* <Toolbar store={store} downloadButtonEnabled /> */}
        <Workspace store={store}
          components={{ PageControls: () => null }}  />
      </WorkspaceWrap>
    </PolotnoContainer>
    </div>
    </div>
  );
};

localforage.getItem('polotno-state', function (err, json) {
  if (json) {
    store.loadJSON(json);
  }
  if (!store.pages.length) {
    store.addPage();
  }
});

store.on('change', () => {
  try {
    const json = store.toJSON();
    localforage.setItem('polotno-state', json);
  } catch (e) {}
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App store={store} />);