import React from 'react';
import { SectionTab } from 'polotno/side-panel';
import FaLock from '@meronex/icons/fa/FaLock';
import logo from '../logo.svg';
export const PrivatePanel = (({ store }) => {

  function handleChange(event) {
    
    var brand = event.target.value.toLowerCase();

    if ( brand === "yanea") {
      store.openSidePanel("Yanea");
    } else if ( brand === "take1") {
      store.openSidePanel("Take1");
    }
  }
  
  return (
    <div className="private-panel">
      <img src={ logo } alt="logo" />
      <p>Hi👋,<br></br> Please enter your Brand Code </p>
    <input name="brandcode" onChange={handleChange} />
    <p className='contact'>Contact us on info@deeseven.digital<br></br>
    to get your custom templates</p>
    </div>
  );

});




// define the new custom section
export const PrivateTemplates = {
  name: 'PrivateTemplates',
  Tab: (props) => (
    <SectionTab name="Private" {...props}>
      <FaLock name="lock" size="20"/>
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: PrivatePanel, 
};
