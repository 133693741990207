import React from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Navbar,
  Alignment,
  AnchorButton,
  Position,
  Menu,
  HTMLSelect,
  Slider,
} from "@blueprintjs/core";
import { downloadFile } from "polotno/utils/download";
import { Popover2 } from "@blueprintjs/popover2";
import * as unit from "polotno/utils/unit";
import { t } from "polotno/utils/l10n";
import MdcFileUploadOutline from "@meronex/icons/mdc/MdcFileUploadOutline";
import MdcContentSaveOutline from "@meronex/icons/mdc/MdcContentSaveOutline";
import BiDownload from "@meronex/icons/bi/BiDownload";
import EnNewMessage from "@meronex/icons/en/EnNewMessage";
import CgTemplate from '@meronex/icons/cg/CgTemplate';
import styled from "polotno/utils/styled";

const NavbarContainer = styled("div")`
  @media screen and (max-width: 500px) {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100vw;
  }
`;

const NavInner = styled("div")`
  @media screen and (max-width: 500px) {
    display: flex;
  }
`;

const DownloadButton = observer(({ store }) => {
  const [saving, setSaving] = React.useState(false);
  const [quality, setQuality] = React.useState(1);
  const [type, setType] = React.useState("png");

  const getName = () => {
    const texts = [];
    store.pages.forEach((p) => {
      p.children.forEach((c) => {
        if (c.type === "text") {
          texts.push(c.text);
        }
      });
    });
    const allWords = texts.join(" ").split(" ");
    const words = allWords.slice(0, 6);
    return words.join(" ").replace(/\s/g, "-").toLowerCase() || "deeseven";
  };
  return (
    <Popover2
      content={
        <Menu>
          <li class="bp4-menu-header">
            <h6 class="bp4-heading">File type</h6>
          </li>
          <HTMLSelect
            fill
            onChange={(e) => {
              setType(e.target.value);
              setQuality(1);
            }}
            value={type}
          >
            <option value="jpeg">JPEG</option>
            <option value="png">PNG</option>
            <option value="pdf">PDF</option>
          </HTMLSelect>
          <li class="bp4-menu-header">
            <h6 class="bp4-heading">Size</h6>
          </li>
          <div style={{ padding: "10px" }}>
            <Slider
              value={quality}
              labelRenderer={false}
              // labelStepSize={0.4}
              onChange={(quality) => {
                setQuality(quality);
              }}
              stepSize={0.2}
              min={0.2}
              max={3}
              showTrackFill={false}
            />
            {type === "pdf" && (
              <div>
                {unit.pxToUnitRounded({
                  px: store.width,
                  dpi: store.dpi / quality,
                  precious: 0,
                  unit: "mm",
                })}{" "}
                x{" "}
                {unit.pxToUnitRounded({
                  px: store.height,
                  dpi: store.dpi / quality,
                  precious: 0,
                  unit: "mm",
                })}{" "}
                mm
              </div>
            )}
            {type !== "pdf" && (
              <div>
                {Math.round(store.width * quality)} x{" "}
                {Math.round(store.height * quality)} px
              </div>
            )}
          </div>
          <Button
            fill
            loading={saving}
            onClick={async () => {
              if (type === "pdf") {
                setSaving(true);
                await store.saveAsPDF({
                  fileName: getName() + ".pdf",
                  dpi: store.dpi / quality,
                  pixelRatio: 2 * quality,
                });
                setSaving(false);
              } else {
                store.pages.forEach((page, index) => {
                  // do not add index if we have just one page
                  const indexString =
                    store.pages.length > 1 ? "-" + (index + 1) : "";
                  store.saveAsImage({
                    pageId: page.id,
                    pixelRatio: quality,
                    mimeType: "image/" + type,
                    fileName: getName() + indexString + "." + type,
                  });
                });
              }
            }}
          >
            {type.toUpperCase()} تحميل   
          </Button>
        </Menu>
      }
      position={Position.BOTTOM_RIGHT}
    >
      <Button
        icon={<BiDownload size="22" />}
        text={t("toolbar.download")}
        className="bp4-button bp4-minimal"
        loading={saving}
        onClick={() => {
          setQuality(1);
        }}
      />
    </Popover2>
  );
});

export default observer(({ store }) => {
  const inputRef = React.useRef();

  return (
    <NavbarContainer className="bp4-navbar">
      <NavInner className="topbar">
        <Navbar.Group align={Alignment.LEFT}>
          <Button
            minimal
            icon={<EnNewMessage size="22" />}
            onClick={() => {
              const ids = store.pages
                .map((page) => page.children.map((child) => child.id))
                .flat();
              const hasObjects = ids?.length;
              if (hasObjects) {
                if (!window.confirm("تصميمك سيتم حذفه، هل أنت متأكد؟")) {
                  return;
                }
              }
              const pagesIds = store.pages.map((p) => p.id);
              store.deletePages(pagesIds);
              store.addPage();
            }}
          >
            جديد
          </Button>

          <Popover2
            content={
              <Menu>
                <label htmlFor="load-project">
                  <Button
                    minimal
                    icon={<MdcFileUploadOutline size="22" />}
                    onClick={() => {
                      document.querySelector("#load-project").click();
                    }}
                  >
                    فتح قالب
                  </Button>
                  <input
                    type="file"
                    id="load-project"
                    accept=".json,.deeseven,.polotno"
                    ref={inputRef}
                    style={{ width: "180px", display: "none" }}
                    onChange={(e) => {
                      var input = e.target;

                      if (!input.files.length) {
                        return;
                      }

                      var reader = new FileReader();
                      reader.onloadend = function () {
                        var text = reader.result;
                        let json;
                        try {
                          json = JSON.parse(text);
                        } catch (e) {
                          alert("Can not load the project.");
                        }

                        if (json) {
                          store.loadJSON(json);
                          input.value = "";
                        }
                      };
                      reader.onerror = function () {
                        alert("Can not load the project.");
                      };
                      reader.readAsText(input.files[0]);
                    }}
                  />
                </label>
                <Button
                  icon={<MdcContentSaveOutline size="22" />}
                  minimal
                  onClick={() => {
                    const json = store.toJSON();

                    const url =
                      "data:text/json;base64," +
                      window.btoa(
                        unescape(encodeURIComponent(JSON.stringify(json)))
                      );

                    downloadFile(url, "DeeSeven-template.json");
                  }}
                >
                  حفظ كقالب
                </Button>
              </Menu>
            }
          >
            <Button
              minimal
              icon={<CgTemplate size="22" />}
             text="قالب"
              />
          </Popover2>
        </Navbar.Group>
        <AnchorButton
          minimal
          href="https://DeeSeven.digital"
          target="_blank"
          id="logobutton"
        ></AnchorButton>
        <Navbar.Group align={Alignment.RIGHT}>
          <DownloadButton store={store} />
          {/* <NavbarHeading>Polotno Studio</NavbarHeading> */}
        </Navbar.Group>
      </NavInner>
    </NavbarContainer>
  );
});
